<div class="profile">
  <div class="profile-row">Hello!</div>
  <div class="profile-row">
    I am Doğan (Doan) and systems engineer based in Gothenburg, Sweden
  </div>

  <div class="profile-row">
    Currently developing in several IT projects and having fun!
  </div>

  <div class="profile-images">
    <img class="profile-image" src="./assets/images/dogan.png" />
  </div>
</div>
