import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token = '';
  constructor(private router: Router) {}
  isLoggedIn(): boolean {
    return this.token !== '';
  }
  isAuthenticated() {
    return this.isLoggedIn;
  }
  login(): Observable<void> {
    return of();
  }
  logout() {}
}
