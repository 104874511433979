import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DemosRoutingModule } from './demos-routing.module';
import { DemosComponent } from '../demos.component';
import { MapModule } from 'src/app/maps/map/map.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ProductModule } from 'src/app/products/product/product.module';

@NgModule({
  declarations: [DemosComponent],
  imports: [
    CommonModule,
    DemosRoutingModule,
    MapModule,
    MatTabsModule,
    ProductModule,
  ],
})
export class DemosModule {}
