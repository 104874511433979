import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../product';

@Component({
  selector: 'one-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableComponent {
  @Input() products$: Observable<Product[]> | undefined;
  @Output() selected = new EventEmitter<Product>();
  selectedProduct: Product | undefined;
  info = 'provided from fakestoreapi.com/products';
  onSelect(product: Product): void {
    this.selectedProduct = product;
    this.selected.emit(product);
  }
}
