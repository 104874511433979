<div class="projects">
  <div class="projects-content">
    <div class="projects-title">
      <span>CLK Enerji’s ambitious Business Transformation</span>
    </div>
    <div class="projects-box">
      <span class="projects-text">Senior GIS Systems Developer</span>
      <span class="projects-text">ArcGIS Desktop Add-in data quality</span>
      <span class="projects-text projects-link" (click)="onClickCK()"
        >More information 1</span
      >
      <span class="projects-text projects-link" (click)="onClickABB()"
        >More information 2</span
      >
      <img class="projects-image" src="./assets/images/esri.svg" />
    </div>
  </div>
  <div class="projects-content">
    <div class="projects-title">
      <span>Novacura Portals- Map</span>
    </div>
    <div class="projects-box">
      <span class="projects-text">Senior Systems Engineer</span>
      <span class="projects-text">ArcGIS API for JavaScript: Angular</span>
      <span class="projects-text projects-link" (click)="onClickNC()"
        >More information</span
      >
      <img class="projects-image" src="./assets/images/NC.jpg" />
    </div>
  </div>
</div>
