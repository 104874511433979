import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Map3dService } from '../services/map3d.service';

@Component({
  selector: 'one-map-3d',
  templateUrl: './map-3d.component.html',
  styleUrls: ['./map-3d.component.scss'],
})
export class Map3DComponent implements OnDestroy, AfterViewInit {
  @ViewChild('mapView', { static: false })
  mapElementRef?: ElementRef;

  constructor(private readonly map3dService: Map3dService) {}
  // ngOnInit(): any {
  //   config.assetsPath = '/assets'; //assuming assets are in /assets
  // }

  ngAfterViewInit(): void {
    this.map3dService.initDefaultMap(this.mapElementRef);
    // this.map3dService.addAllMapWidgets();
  }

  ngOnDestroy(): void {
    if (this.map3dService.map3dView) {
      this.map3dService.map3dView.destroy();
    }
  }
}
