<div class="container-fluid overflow-y-auto" style="height: 70vh">
  <div class="row">
    <div
      class="col-md-4 customTable overflow-y-auto"
      fxLayoutAlign="start start"
    >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Product Table</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <one-product-table
            [products$]="products$"
            (selected)="selectedProduct = $event"
          ></one-product-table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4 overflow-y-hidden" fxLayoutAlign="start start">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Product Detail</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <one-product-detail
            *ngIf="selectedProduct !== undefined; else noProduct"
            [id]="selectedProduct.id"
            (bought)="onBuy()"
            (deleted)="onDelete()"
          ></one-product-detail>
          <ng-template #noProduct>
            <div class="no-product">
              <span>No Product Selected!</span>
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4" fxLayoutAlign="start start">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Create Product</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <one-product-create (added)="onAdd($event)"></one-product-create>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<!-- <h2>Favorites</h2>
<one-favorites></one-favorites> -->
