import { Injectable } from '@angular/core';
import { IEnvironment } from '../../models/environment';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  get production(): boolean {
    return environment.production;
  }

  get baseConfigs(): any {
    return environment.baseConfigs;
  }

  get randomPtsPhxUrl(): string {
    return environment.randomPtsPhxUrl;
  }
}
