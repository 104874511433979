<div class="skills">
  <div class="skills-title"><span class="skills-text">Front-End</span></div>
  <div class="skills-section">
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/typescript.svg" />
      <span class="skills-text">Typescript</span>
    </div>
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/javascript.svg" />
      <span class="skills-text">Javascript</span>
    </div>
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/angular.svg" />
      <span class="skills-text">Angular</span>
    </div>
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/esri.svg" />
      <span class="skills-text">ArcGIS Javascript</span>
    </div>
  </div>
  <div class="skills-title"><span class="skills-text">Back-End</span></div>
  <div class="skills-section">
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/c--4.svg" />
      <span class="skills-text">C#</span>
    </div>
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/microsoft-net.svg" />
      <span class="skills-text">NET</span>
    </div>
    <div class="skills-box">
      <img class="skills-image" src="./assets/images/esri.svg" />
      <span class="skills-text">ArcGIS Addin</span>
    </div>
  </div>
</div>
