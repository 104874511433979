import { Injectable } from '@angular/core';
import { ProductsService } from '../products.service';
import { Product } from '../product';
import { Observable } from 'rxjs';

@Injectable()
export class ProductViewService {
  private product: Product | undefined;
  //  service-in-a-service
  constructor(private productService: ProductsService) {}
  getProduct(id: number): Observable<Product | undefined> {
    return this.productService.getProduct(id);
    // if (!this.product) {
    //   this.product = products[id];
    // }
    // return this.product;
  }
}
