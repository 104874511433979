import Map from '@arcgis/core/Map';
import { ElementRef, Injectable } from '@angular/core';
import SceneView from '@arcgis/core/views/SceneView.js';
import { EnvironmentService } from './environment.service';
import Camera from '@arcgis/core/Camera.js';
import SceneLayer from '@arcgis/core/layers/SceneLayer.js';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery.js';
import LayerList from '@arcgis/core/widgets/LayerList.js';
import Expand from '@arcgis/core/widgets/Expand.js';

@Injectable({
  providedIn: 'root',
})
export class Map3dService {
  map?: Map;
  map3dView?: SceneView;
  constructor(private readonly environment: EnvironmentService) {}

  initDefaultMap(mapElementRef?: ElementRef): void {

    this.map = new Map({
      basemap: 'satellite',
      ground: 'world-elevation',
    });

    const cam = new Camera({
      heading: 245, // face due east
      tilt: 25, // looking from a bird's eye view
      position: {
        latitude: 57.70887,
        longitude: 11.97456,
        z: 20000,
        spatialReference: { wkid: 3857 },
      },
    });

    const sceneLayer = new SceneLayer({
      url: 'https://services5.arcgis.com/04gBMzU1FtxfoDdi/ArcGIS/rest/services/Uppsala_3D_Buildings/SceneServer/layers/0',
      popupEnabled: false,
    });
    const sceneLayer2 = new SceneLayer({
      url: 'https://services5.arcgis.com/04gBMzU1FtxfoDdi/ArcGIS/rest/services/Uppsala_3D_Trees/SceneServer/layers/0',
      popupEnabled: false,
    });
    const sceneLayer3 = new SceneLayer({
      url: 'https://tiles.arcgis.com/tiles/nSZVuSZjHpEZZbRo/arcgis/rest/services/BAG_3D_WGS/SceneServer',
      popupEnabled: false,
    });

    const sceneLayer4 = new SceneLayer({
      url: 'https://basemaps3d.arcgis.com/arcgis/rest/services/OpenStreetMap3D_Buildings_v1/SceneServer',
      popupEnabled: false,
    });

    this.map.add(sceneLayer);
    this.map.add(sceneLayer2);
    this.map.add(sceneLayer3);
    this.map.add(sceneLayer4);

    const view = new SceneView({
      map: this.map,
      camera: cam,
      container: mapElementRef?.nativeElement,
    });

    let basemapGallery = new BasemapGallery({
      view: view,
    });
    let bgExpand = new Expand({
      view: view,
      content: basemapGallery,
      group: 'top-right',
    });

    let layerList = new LayerList({
      view: view,
    });

    let layerListExpand = new Expand({
      expandIcon: 'layers',
      view: view,
      content: layerList,
      group: 'top-right',
    });
    view.ui.add([bgExpand, layerListExpand], 'top-right');
  }
}
