import { BasemapId } from '../app/maps/enums/basemap-id';
import { WidgetPosition } from '../app/maps/enums/widget-poistion';

const arcgisVersion = '4.27';

export const baseConfigs = {
  arcgisJsApiSettings: {
    apiUrl: `https://js.arcgis.com/${arcgisVersion}/`,
    cssUrl: `https://js.arcgis.com/${arcgisVersion}/esri/css/main.css`,
  },
  defaultMapSettings: {
    centerLat: 57.70887,
    centerLon: 11.97456,
    zoomLevel: 5,
    basemapId: BasemapId.streetsVector,
    widgets: {
      basemapToggle: {
        position: WidgetPosition.topLeft,
        nextBasemap: BasemapId.hybrid,
      },
      zoom: {
        position: WidgetPosition.topRight,
      },
    },
  },
};
