import { Component } from '@angular/core';

@Component({
  selector: 'one-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent {
  onClickWipro(): void {
    window.open(
      'https://www.wipro.com/utilities/videos/wipro-implements-clk-enerjis-ambitious-business-transformation/'
    );
  }

  onClickCK(): void {
    window.open(
      'https://www.iot-now.com/2016/01/28/41630-turkeys-energy-provider-clk-enerji-selects-oracle-utilities-for-business-transformation/'
    );
  }

  onClickABB(): void {
    window.open(
      'https://new.abb.com/news/sv/detail/37771/ventyx-i-samarbete-med-wipro-for-att-leverera-eam-losningar-till-foretag-globalt'
    );
  }

  onClickNC(): void {
    window.open('https://www2.novacura.com/flow-portal-2');
  }
}
