import { Component } from '@angular/core';

@Component({
  selector: 'one-demos',
  templateUrl: './demos.component.html',
  styleUrls: ['./demos.component.scss'],
})
export class DemosComponent {
  is2dMap: boolean = true;
  is3dMap: boolean = false;

  onMap2D(): void {
    this.is2dMap = true;
    this.is3dMap = false;
  }

  onMap3D(): void {
    this.is2dMap = false;
    this.is3dMap = true;
  }
}
