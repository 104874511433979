import { Component, Inject, OnInit } from '@angular/core';
import config from '@arcgis/core/config.js';
import { APP_CONFIG, AppConfig, appSettings } from './config/app-config';
import { Observable } from 'rxjs';
@Component({
  selector: 'one-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: appSettings,
    },
  ],
})
export class AppComponent implements OnInit {
  pageTitle$ = new Observable((observer) => {
    setInterval(() => {
      observer.next();
    }, 2000);
  });
  title = 'Doğan Alkan';
  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.pageTitle$.subscribe(this.setTitle);
  }
  ngOnInit(): void {
    config.assetsPath = './assets';
  }

  private setTitle = () => {
    const timeStamp = new Date().toDateString();
    const hours = new Date().getTime().toString();
    this.title = 'Time: ' + timeStamp + ' - ' + hours;
  };
}
