import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoritesComponent } from '../favorites/favorites.component';
import { ProductListComponent } from '../product-list/product-list.component';
import { SortPipe } from 'src/app/shared/directives/sort.pipe';
import { ProductViewComponent } from '../product-view/product-view.component';
import { ProductDetailComponent } from '../product-detail/product-detail.component';
import { ProductCreateComponent } from '../upsert/product-create/product-create.component';
import { MatCardModule } from '@angular/material/card';
import { ProductTableComponent } from '../product-table/product-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FavoritesComponent,
    ProductListComponent,
    ProductViewComponent,
    ProductDetailComponent,
    ProductCreateComponent,
    ProductTableComponent,
    SortPipe,
  ],
  imports: [CommonModule, MatCardModule, ReactiveFormsModule, FormsModule],
  exports: [FavoritesComponent, ProductListComponent],
})
export class ProductModule {}
