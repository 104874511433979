import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Product } from '../../product';
import { ProductsService } from '../../products.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FLOATING_REGEXP } from '../../product/constants';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/shared/services/destroy.service';

@Component({
  selector: 'one-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ProductCreateComponent implements OnInit {
  @Output() added = new EventEmitter<Product>();

  form: FormGroup = this.formBuilder.group({
    productName: new FormControl(),
    productPrice: new FormControl(),
  });
  isFormSubmitted = false;

  constructor(
    private productService: ProductsService,
    private _destroy$: DestroyService,
    private formBuilder: FormBuilder
  ) {
    this.form = new FormGroup({
      productName: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(4)])
      ),
      productPrice: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(FLOATING_REGEXP),
        ])
      ),
    });
    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.onValueChanged(data);
    });
  }

  ngOnInit(): void {
    this.validateForm();
  }

  addProduct(productName: string, productPrice: number) {
    this.productService
      .addProduct(productName, productPrice)
      .subscribe((product) => this.added.emit(product));
  }

  validateForm(): void {
    this.isFormSubmitted = true;
    if (this.form?.valid) {
      const { productName, productPrice } = this.form.controls;
      this.productService
        .addProduct(productName.value, productPrice.value)
        .subscribe((product) => {
          this.added.emit(product);
          alert(`${product.name} has been created`);
        });
    }
  }

  get productName() {
    return this.form.get('productName');
  }

  get productPrice() {
    return this.form.get('productPrice');
  }

  onValueChanged(data?: any) {
    if (!data) {
      return;
    }
    // Do something!
  }
}
