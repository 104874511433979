import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'one-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss'],
})
export class HeadersComponent {
  navbarOpen = false;
  constructor(private router: Router) {}
  onLogoClick(): void {
    this.router.navigate(['/welcome']);
  }
  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }
}
