<div class="container-fluid">
  <div class="row">
    <div class="col logo" (click)="onLogoClick()" [title]="'Home'">
      <img class="logo-image" src="./assets/images/logo.svg" />
    </div>
    <div class="col items">
      <nav class="navbar navbar-expand-md">
        <button
          class="navbar-toggler ml-auto custom-toggler"
          type="button"
          (click)="toggleNavbar()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngClass]="{ show: navbarOpen }">
          <ul class="navbar-nav mr-auto nav-pills">
            <li class="nav-item active">
              <a
                class="nav-link"
                [routerLink]="['/welcome']"
                routerLinkActive="active"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/skills']"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Skills</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/projects']"
                routerLinkActive="active"
                >Projects</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="['/demos']"
                routerLinkActive="active"
                >Demos</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
