<div class="demos">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Map 2D"
      ><one-map-2d class="demos-tab"></one-map-2d
    ></mat-tab>
    <mat-tab label="Map 3D">
      <one-map-3d class="demos-tab"></one-map-3d>
    </mat-tab>
    <mat-tab label="Products">
      <one-product-list class="demos-tab"></one-product-list>
    </mat-tab>
  </mat-tab-group>
</div>
