import { Injectable } from '@angular/core';
import { ProductsService } from '../products.service';
import { Product } from '../product';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FavoritProductService extends ProductsService {
  constructor(private httClientOverride: HttpClient) {
    super(httClientOverride);
  }

  override getProducts(): Observable<Array<Product>> {
    return super.getProducts().pipe(
      map((products) =>
        products.slice(1, 3).map((product) => {
          return product;
        })
      )
    );
  }
}
