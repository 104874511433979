import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Map2DComponent } from '../map-2d/map-2d.component';
import { Map3DComponent } from '../map-3d/map-3d.component';

@NgModule({
  declarations: [Map2DComponent, Map3DComponent],
  imports: [CommonModule],
  exports: [Map2DComponent, Map3DComponent],
})
export class MapModule {}
