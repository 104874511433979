<div class="container-fluid mt-2">
  <div *ngIf="form?.invalid" class="alert alert-info" role="alert">
    Enter values in the form.
  </div>
  <form
    [formGroup]="form"
    [class.was-validated]="isFormSubmitted"
    class="needs-validation"
  >
    <div class="row my-1">
      <div class="form-group">
        <label for="productName">Product name:</label>
        <input
          type="text"
          class="form-control"
          formControlName="productName"
          id="productName"
          placeholder="Enter product name"
          name="productName"
          [ngClass]="{
            'is-invalid': isFormSubmitted && productName?.errors
          }"
          required
        />
        <div *ngIf="productName?.errors?.['required']" class="invalid-feedback">
          Name cannot be empty.
        </div>
        <div
          *ngIf="productName?.errors?.['minlength']"
          class="invalid-feedback"
        >
          Name must have at least 4 characters.
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="form-group">
        <label for="productPrice">Product price:</label>
        <input
          type="float"
          class="form-control"
          id="productPrice"
          placeholder=""
          name="productPrice"
          formControlName="productPrice"
          [ngClass]="{
            'is-invalid': isFormSubmitted && productPrice?.errors
          }"
          required
        />
        <div
          class="invalid-feedback"
          *ngIf="productPrice?.errors?.['required']"
        >
          Price cannot be empty.
        </div>
        <div class="invalid-feedback" *ngIf="productPrice?.errors?.['pattern']">
          Product price must be a positive integer or decimal
        </div>
      </div>
    </div>
    <button
      type="button"
      (click)="validateForm()"
      [disabled]="form?.invalid"
      class="w-100 mt-4 mb-2 btn-lg btn btn-primary"
    >
      Create
    </button>
  </form>
</div>
