import {
  ChangeDetectionStrategy,
  Host,
  Optional,
  Component,
  OnInit,
} from '@angular/core';
import { ProductsService } from '../products.service';
import { Product } from '../product';
import { FavoritProductService } from '../services/favorit-product.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'one-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ProductsService, useClass: FavoritProductService }],
})
export class FavoritesComponent implements OnInit {
  products$: Observable<Product[]> | undefined;

  constructor(@Host() @Optional() private productsService: ProductsService) {}

  ngOnInit(): void {
    this.products$ = this.productsService.getProducts();
  }
}
