import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ProductViewService } from '../services/product-view.service';

@Component({
  selector: 'one-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductViewService],
})
export class ProductViewComponent implements OnInit {
  @Input() id = -1;
  name = '';
  constructor(private productViewService: ProductViewService) {}
  ngOnInit(): void {
    if (this.id) {
      this.productViewService
        .getProduct(this.id)
        .subscribe((product) => (this.name = product ? product.name : ''));
    }
  }
}
