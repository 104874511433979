import { ChangeDetectorRef, Component } from '@angular/core';
import { Product } from '../product';
import { Observable, map, tap } from 'rxjs';
import { ProductsService } from '../products.service';

@Component({
  selector: 'one-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  products$: Observable<Product[]> | undefined;
  selectedProduct: Product | undefined;
  info = 'provided from fakestoreapi.com/products';
  constructor(
    private productService: ProductsService,
    private cdr: ChangeDetectorRef
  ) {
    this.getProducts();
  }
  onBuy(): void {
    window.alert(`You are buying ${this.selectedProduct?.name}!`);
  }
  onAdd(product: Product): void {
    this.products$?.pipe(map((products) => products.push(product)));
    this.cdr.detectChanges();
  }
  onDelete(): void {
    this.products$?.pipe(
      map((products) =>
        products.filter((product) => product !== this.selectedProduct)
      ),
      tap(() => (this.selectedProduct = undefined))
    );
  }
  private getProducts(): void {
    this.products$ = this.productService.getProducts();
  }
}
