<form
  [formGroup]="form"
  [class.was-validated]="isFormSubmitted"
  class="needs-validation"
>
  <div
    *ngIf="product$ | async as product; else loading"
    class="container-fluid text-center"
  >
    <div class="row align-items-start">
      <div class="col-md-4 text-start">Name:</div>
      <div class="col-md-8 badge bg-primary text-wrap">
        {{ product.name }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col-md-2">Price:</div>
      <div class="col-md-2">{{ product.price | currency : "EUR" }}</div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <label for="productPrice">New product price:</label>
        <input
          type="float"
          class="form-control"
          id="price"
          name="price"
          formControlName="price"
          [ngClass]="{
            'is-invalid': isFormSubmitted && price?.errors
          }"
          required
        />
        <div class="invalid-feedback" *ngIf="price?.errors?.['required']">
          Price cannot be empty.
        </div>
        <div class="invalid-feedback" *ngIf="price?.errors?.['pattern']">
          Product price must be a positive integer or decimal
        </div>
      </div>
      <div class="col mt-1">
        <button
          class="w-100 mt-1 mb-1 btn-lg btn btn-primary"
          title="update"
          (click)="changePrice(product)"
        >
          Update
        </button>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <button class="w-100 mt-1 mb-1 btn-lg btn btn-success" (click)="buy()">
          Buy Now
        </button>
      </div>
      <div class="col">
        <button
          class="w-100 mt-1 mb-1 btn-lg btn btn-danger"
          title="delete"
          (click)="deleteProduct(product)"
        >
          Delete
        </button>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-template>
</form>
