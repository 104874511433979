import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Product } from '../product';
import { ProductsService } from '../products.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FLOATING_REGEXP } from '../product/constants';

@Component({
  selector: 'one-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailComponent implements OnInit, OnChanges {
  @Input() id: number = -1;
  @Output() deleted = new EventEmitter<Product>();
  @ViewChild('price') inputName: ElementRef | undefined;
  product$: Observable<Product> | undefined;

  form: FormGroup = this.formBuilder.group({
    price: new FormControl(),
  });
  isFormSubmitted = false;
  constructor(
    private readonly productService: ProductsService,
    public authService: AuthService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this.product$ = this.productService.getProduct(this.id);
    this.product$.subscribe((product) => {
      this.form = new FormGroup({
        price: new FormControl(
          product.price,
          Validators.compose([
            Validators.required,
            Validators.pattern(FLOATING_REGEXP),
          ])
        ),
      });
    });
  }
  ngOnChanges() {
    this.product$ = this.productService.getProduct(this.id);
  }
  buy() {}

  changePrice(product: Product): void {
    this.isFormSubmitted = true;
    if (this.form?.valid) {
      const { price } = this.form.controls;
      this.productService
        .updateProduct(product.id, price.value)
        .subscribe(() =>
          alert(`${product.name} price has been changed as ${price.value}`)
        );
    }
    if (this.inputName) {
      this.inputName.nativeElement.value = ' ';
    }
  }
  deleteProduct(product: Product) {
    this.productService
      .deleteProduct(product.id)
      .subscribe(() => this.deleted.emit(product));
  }

  get price() {
    return this.form.get('price');
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   const product = changes['name'];
  //   if (!product.isFirstChange()) {
  //     const oldValue = product.previousValue;
  //     const newValue = product.currentValue;
  //     console.log('Product changed from ${oldValue} to ${newValue}');
  //   }
  // }
}
