<div class="container-fluid">
  <div *ngFor="let product of (products$ | async)! | sort; let i = index">
    <div class="row" (click)="onSelect(product)">
      <!-- <div class="col"><one-product-view [id]="i"></one-product-view></div> -->
      <div
        class="col"
        [ngClass]="{ 'row-selected': selectedProduct === product }"
      >
        {{ product.name }}
      </div>
    </div>
  </div>
  <span>{{ info }}</span>
</div>
