import { Injectable } from '@angular/core';
import { Product } from './product';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { ObserversModule } from '@angular/cdk/observers';
export interface ProductDTO {
  id: number;
  title: string;
  price: number;
}
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly productsUrl = 'https://fakestoreapi.com/products';
  constructor(private httClient: HttpClient) {}

  getProducts(): Observable<Product[]> {
    const options = {
      headers: new HttpHeaders({ Authorization: 'myAuthToken' }),
    };
    return this.httClient.get<ProductDTO[]>(this.productsUrl, options).pipe(
      map((products) =>
        products.map((product) => {
          return this.convertToProduct(product);
        })
      )
    );
  }

  getProduct(id: number): Observable<Product> {
    return this.httClient
      .get<ProductDTO>(`${this.productsUrl}/${id}`)
      .pipe(map((product) => this.convertToProduct(product)));
  }

  addProduct(name: string, price: number): Observable<Product> {
    return this.httClient
      .post<ProductDTO>(this.productsUrl, {
        title: name,
        price: price,
      })
      .pipe(map((product) => this.convertToProduct(product)));
  }

  updateProduct(id: number, price: number): Observable<void> {
    return this.httClient.patch<void>(`${this.productsUrl}/${id}`, { price });
  }

  deleteProduct(id: number): Observable<void> {
    return this.httClient.delete<void>(`${this.productsUrl}/${id}`);
  }

  private convertToProduct(product: ProductDTO): Product {
    return {
      id: product.id,
      name: product.title,
      price: product.price,
    };
  }

  // getProducts(): Product[] {
  //   return [
  //     {
  //       name: 'Webcam',
  //       price: 100,
  //     },
  //     {
  //       name: 'Microphone',
  //       price: 200,
  //     },
  //     {
  //       name: 'Wireless keyboard',
  //       price: 85,
  //     },
  //   ];
  // }
}
