import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { MapService } from '../services/map.service';

@Component({
  selector: 'one-map-2d',
  templateUrl: './map-2d.component.html',
  styleUrls: ['./map-2d.component.scss'],
})
export class Map2DComponent implements OnDestroy, AfterViewInit {
  @ViewChild('mapView', { static: false })
  mapElementRef?: ElementRef;

  constructor(private readonly mapService: MapService) {}

  // ngOnInit(): any {
  //   config.assetsPath = '/assets'; //assuming assets are in /assets
  // }

  ngAfterViewInit(): void {
    this.mapService.initDefaultMap(this.mapElementRef);
    this.mapService.addAllMapWidgets();
  }

  ngOnDestroy(): void {
    if (this.mapService.mapView) {
      this.mapService.mapView.destroy();
    }
  }
}
